export default {
    welcome: "Bienvenido",
    nonexistentKey: "Texto por defecto",
    routes:{
        BRMNews:"Actualidad",
        details:"Detalles",
        Podcast:"Podcast",
        apps: "Aplicativos",
        profile:"Mi perfil empresarial",
        carnet:"Carnet",
        carneSecurity:"Carnet seguridad",
        Requests: "Requests",
        BRMPrograms: "Programas",
        IHaveTheSheet: "Le tengo la ficha",
        LifeControl: "Control de vida",
        LaborRelations: "Relaciones laborales",
        Documents: "Documentos",
        Referrals: "Referidos",
        ReferralDetail: "Referidos Detalle",
        Linguo:"Linguo",
        LinguoDev: "Linguo dev",
        LinguoBrands: "Linguo marcas",
        InformationSecurity: "Seguridad de la Información",
        AbsenceList: "Lista de ausencias",
        DeliveryReport: "Acta Entrega",
        MinutesReport: "Reporte de Actas",
        DeletedMinutesReport: "Reporte de Actas Eliminadas",
        BRMQuestion: "BRM Pregunta",
        Form: "Formulario",
        Answer: "Respuesta",
        RegulationsAndPolicies: "Reglamentos y Políticas",
        Detail: "Detalle",
        CorporateDocuments: "Documentos corporativos",
        Signature: "Firma",
        PayrollReport: "Reporte de nómina",
        NonSalariedBonus: "Bono no salarial",
        NonSalariedBonusReport: "Reporte de bono no salarial",
        DeletedBonusReport: "Reporte de bonos eliminados",
        DocumentsReport: "Reporte documentos",
        DeletedDocumentsReport: "Reporte documentos eliminados",
        LoginReport: "Reporte Login",
        ShiftGrid: "Malla de turno",
        Claims: "Reclamaciones",
        AttachFile: "Adjuntar archivo",
        ReportByUsers: "Reporte por usuarios",
        ReportByPercentages: "Reporte por porcentajes",
        Provisions: "Provisiones",
        AttachProvisions: "Adjuntar provisiones",
        Notifications: "Notificaciones",
        AttractionAndSelection: "Atracción y selección",
        CreateVacancies: "Crear vacantes",
        ListCandidates: "Listar candidatos",
        CandidateDetails: "Detalle de candidatos",
        ListVacancies: "Listar vacantes",
        ListCandidates: "Listar Candidatos",
        NewDevelopment: "Nuevo Desarrollo",
        OwnRequests: "Solicitudes propias",
        CreateNewRequest: "Crear nueva solicitud",
        CreatedRequests: "Solicitudes creadas",
        OngoingRequests: "Solicitudes en curso",
        DraftRequest: "Solicitud en Borrador",
        EditDraftRequest: "Editar solicitud en Borrador",
        CopyRequests: "Solicitudes en copia",
        RequestDetails: "Detalle de solicitud",
        PMRequests: "Solicitudes PM",
        AssignedRequests: "Solicitudes Asignadas",
        DisciplinaryProcesses: "Procesos disciplinarios",
        CreateDisciplinaryProcess: "Crear proceso disciplinario",
        DisciplinaryProcessDetails: "Detalle proceso disciplinario",
        RecognitionsForYou: "Reconocimientos para ti",
        SuperStars: "Super Stars",
        DeliveryStars: "Delivery Stars",
        AceOfSpades: "Ace Of Spades",
        AllWinners: "Todos los ganadores",
        UpdatingWinners: "Actualizar ganadores",
        DeletingWinners: "Eliminar ganadores",
        Agreements: "Convenios",
    },
    titleApp:{
        login: "Inicio de sesión",
        unauthorizedOkan: "Okan sin autorización",
        Home:"Inicio Home",
        Podcast: "Podcast",
        BRMNews: "Actualidad",
        Apps: "Aplicativos",
        MyProfile: "Mi perfil",
        Overtime: "Horas extras",
        VoluntaryRetirement: "Retiro voluntario",
        Requests: "Solicitudes",
        VoluntaryResignationReport: "Reporte de renuncia voluntaria",
        BRMPrograms: "Programas BRM",
        IHaveTheFile: "Le tengo la ficha",
        LifeControl: "Control de vida",
        LaborRelations: "Relaciones laborales",
        LaborRelationsDocuments: "Relaciones laborales documentos",
        ReferralReport: "Reporte de referidos",
        Linguo: "Linguo",
        LinguoForDevelopers: "Linguo para desarrolladores",
        LinguoForBrands: "Linguo para marcas",
        SecurityModule: "Módulo de seguridad",
        CorporateDocumentsForManagers: "Documentos corporativos para gerentes",
        ActReports: "Reporte de Actas",
        DeletedActReports: "Reporte de Actas eliminadas",
        BRMQuestion: "BRM Pregunta",
        FormDetails: "Detalles de formularios",
        FormResponses: "Respuestas formularios",
        SafeConduct: "Salvo conducto",
        RegulationsAndPolicies: "Reglamentos y Políticas",
        TermsAndConditions: "Términos y condiciones",
        CorporateDocuments: "Documentos corporativos",
        CorporateDocumentsDetails: "Detalles de documentos corporativos",
        CorporateDocumentsForManagers: "Documentos corporativos para gerentes",
        NonSalaryBonusDocumentReport: "Reporte de documentos de bono no salarial",
        DeletedNonSalaryBonusDocumentsReport: "Reporte de documentos de bono eliminados",
        CorporateDocuments: "Documentos corporativos",
        DocumentsReport: "Reporte de documentos",
        DeletedDocumentsReport: "Reporte de documentos eliminados",
        OKANIncomeReport: "Reporte de Ingresos OKAN",
        ConsolidatedReport: "Informe Consolidado",
        Occupation: "Ocupación",
        ShiftSchedule: "Malla de turno",
        Commissions: "Comisiones",
        CommissionMargins: "Margen de comisiones",
        AttachCommissionMargins: "Adjuntar márgenes de comisiones",
        SignaturesReport: "Reporte de firmas",
        SignaturesReportByPercentage: "Reporte de firmas por porcentaje",
        CommissionMinutes: "Acta de comisiones",
        CommissionProvision: "Provisión de comisiones",
        UpdateCommissionProvision: "Actualizar provisión de comisiones",
        Notifications: "Notificaciones",
        RecruitmentAndSelection: "Atracción y selección",
        CreateVacancies: "Crear vacantes",
        ListCandidates: "Listar candidatos",
        CandidateDetails: "Detalle de candidatos",
        ListVacancies: "Listar vacantes",
        NewDevelopment: "Solicitudes desarrollo",
        WorkforceRequests: "Solicitudes Workforce",
        OwnRequests: "Solicitudes propias",
        CreateNewRequest: "Crear nueva solicitud",
        CreatedRequests: "Solicitudes creadas",
        RequestsInProgress: "Solicitudes en curso",
        DraftRequest: "Solicitud en borrador",
        EditDraftRequest: "Editar solicitud en borrador",
        CopiedRequests: "Solicitudes en copia",
        RequestDetails: "Detalle de solicitud",
        AssignedRequests: "Solicitudes asignadas",
        DisciplinaryProcesses: "Procesos disciplinarios",
        CreateNewDisciplinaryProcess: "Crear un nuevo proceso disciplinario",
        DisciplinaryProcessList: "Lista de procesos disciplinarios",
        DisciplinaryProcessDetails: "Detalle del proceso disciplinario",
        IDCard: "Carnet",
        SecurityIDCard: "Carnet de seguridad",
        RecognitionForYou: "Reconocimientos para ti",
        SuperStars: "Super Stars",
        DeliveryStars: "Delivery Stars",
        AceOfSpades: "Ace of Spades",
        AllWinners: "Todos los ganadores",
        UpdatingWinners: "Actualizar ganadores",
        DeletingWinners: "Eliminar ganadores",
        AllWinners: "Todos los ganadores",
        RappiRanking: "Rappi Ranking",
        Agreements: "Convenios",
        AgreementDetails: "Detalle Convenio"
    },
    sidebar:{
        Home: "Inicio",
        BRMNews: "Actualidad",
        Podcast: "Pódcast",
        Apps: "Aplicativos",
        CardCode: "Codigo carnet",
        CorporateDocuments: "Documentos corporativos",
        BRMQuestion: "BRM Pregunta",
        Requests: "Solicitudes",
        BRMPrograms: "Programas BRM",
        RulesAndPolicies: "Reglamentos y Políticas",
        MyBusinessProfile: "Mi perfil empresarial",
        Referrals: "Referidos",
        PayrollReport: "Reporte de nómina",
        LaborRelations: "Relaciones laborales",
        AttractionAndSelection: "Atracción y selección",
        NewDevelopments: "Solicitudes desarrollo",
        WorkforceRequests: "Solicitudes Workforce",
        AIDevelopmentRequests: "Solicitudes Desarrollo IA",
        ShiftGrid: "Malla de turno",
        InformationSecurity: "Seguridad de la información",
        UserUpdate: "Actualización Usuario",
        Logout: "Cerrar sesión",
    },
    navBar:{
        language: "Idioma",
        noNotificationsAvailable: "No hay notificaciones disponibles",
        recentNotifications: "Notificaciones recientes",
        viewAll: "Ver todas",
        notificationNumber: "N° de notificación",
        noFrequentAppsFound: "No se encontraron app frecuentes",
        loading: "Cargando..."
    },
    home: {
        recognitions:"Reconocimientos",
        OtherNews:"Otras noticias",
        toDoToday:"¿Qué desea hacer hoy?",
        buttonSeeMore: "Ver más", 
    },
    indexNews:{
        birthday:"Hoy es cumpleaños de:",
        noBirthday:"¡Hoy nadie está cumpliendo años!",
        BRMFlash:"Flash",
        buttonSeeMore: "Ver más",
    },
    newsGrid:{
        news: "Actualidad",
        buttonSeeMore: "Ver más",
    },
    NewsDetails:{
        buttonSeeMore: "Ver más",
    },
    flash:{
        buttonClose:"Cerrar"
    },
    actionToday:{
        index: {
            reportSick: "Reportar incapacidad",
            requestPermission: "Solicitar permiso",
            requestVacation: "Solicitar vacaciones",
            requestCertificates: "Solicitar certificados",
            viewProfile: "Ver perfil",
            viewSurveys: "Ver encuestas"
          },
        buttonSeeMore: "Ver más",
    },
    podcast:{
        title: "Pódcast",
        buttonClose:"Cerrar",
        play:"Reproducir"
    },
    apps:{
        title: "Aplicativos",
        all:"Todos",
        favorite:"Favoritos",
        AplicativosGoogle:" Aplicativos Google",
        AplicativosBRM:" Aplicativos",
        AplicativosCliente:" Aplicativos Cliente",
        AplicativosInteligenciaArtificial:" Aplicativos Inteligencia Artificial",
        Reportes:"Reportes",
        AplicativosExternos:"Aplicativos Externos",
        AplicativosMicrosoft:"Aplicativos Microsoft",
        AplicativosdeMensajeria:"Aplicativos de Mensajeria",
        search:"Buscar"

    },
    profile:{
        title: "Mi perfil empresarial",
        buttonEdit:"Editar",
        updateData:"Actualizar datos",
        generateCard:"Generar Carnet",
        workedMonths: "Meses trabajados",
        daysToBirthday: "Días para su cumpleaños",
        myReferrals: "Mis referidos",
        indexTab:{
            personnel: "Personal",
            contact: "Contacto",
            contractual: "Contractual",
            followUp: "Seguimiento"    
        },
        personal:{
            personalInformation: "Información personal",
            fullNames: "Nombres completos",
            lastNames: "Apellidos",
            documentType: "Tipo de documento",
            documentNumber: "Número de documento",
            documentIssuanceDate: "Fecha de expedición de documento",
            birthDate: "Fecha de nacimiento",
            gender: "Género",
            age: "Edad",
            maritalStatus: "Estado civil",
            academicLevel: "Nivel académico",
            healthPromotingEntity: "Entidad Promotora de Salud (EPS)",
            pensionFund: "Fondo de Pensiones"
        },
        contact:{
            contactInformation: "Información de contacto",
            department: "Departamento",
            city: "Ciudad",
            locality: "Localidad",
            neighborhood: "Barrio",
            address: "Dirección",
            personalEmail: "Correo personal",
            socioeconomicStratum: "Estrato",
            housingType: "Tipo de vivienda",
            phone: "Teléfono",
            landline: "Teléfono local"
        },
        contractual:{
            contractualInformation: "Información contractual",
            position: "Cargo",
            positionType: "Tipo de cargo",
            status: "Estado",
            corporateEmail: "Correo corporativo",
            contractType: "Tipo de contrato",
            entryDate: "Fecha de ingreso",
            retirementDate: "Fecha de retiro",
            contractor: "Contratante",
            costCenter: "Centro de costos",
            subCostCenter: "Sub centro de costos",
            client: "Cliente",
            campaign: "Campaña",
            headquarters: "Sede",
            headquartersCity: "Ciudad de la sede"
        },
        followUp:{
           trackingInformation: "Información de seguimiento",
           noTrackingInformation: "No tiene información de seguimiento"
        },
        update:{
            dataUpdate: "ACTUALIZACIÓN DE DATOS",
            businessProfile: "Mi perfil empresarial",
            personalInformation: "Información personal",
            firstName: "Nombre",
            lastName: "Apellidos",
            fullName: "Nombre completo",
            documentType: "Tipo de documento",
            documentNumber: "Número de documento",
            documentIssuanceDate: "Fecha expedición",
            birthDate: "Fecha nacimiento",
            age: "Edad",
            gender: "Género",
            maritalStatus: "Estado civil",
            academicLevel: "Nivel académico",
            healthPromotingEntity: "Entidad promotora de salud (eps)",
            pensionFund: "Fondo de pensiones",
            contactInformation: "Información de contacto",
            personalEmail: "Correo personal",
            mobile: "Celular",
            phone: "Teléfono",
            socioeconomicStratum: "Estrato",
            housingType: "Tipo de vivienda",
            department: "Departamento",
            city: "Ciudad",
            locality: "Localidad",
            neighborhood: "Barrio",
            addressInformation: "Información de dirección",
            address: "Dirección",
            dianAddress: "Dirección de la DIAN",
            nomenclature: "Nomenclatura",
            additionalInformation: "Información adicional",
            LIMPIAR: "LIMPIAR",
            AGREGAR: "AGREGAR",
            editInformation: "EDITAR INFORMACIÓN"
        },
        popUp:{
            updateYourData: "¡ACTUALIZA TUS DATOS!",
            updateDescription: "Actualizar tus datos personales te permite tener información precisa y actualizada, proteger tu seguridad, hacer tu vida más cómoda y ahorrar tiempo.",
            updateInstruction: "Para actualizar tu información en Okan, da clic en el botón de",
            updateText:"'Actualizar datos'.",
            close: "Cerrar",
            changeImage: "Cambiar imagen",
            fileRestriction: "Solo se permiten archivos en formato: png, jpeg y bmp.",
            save: "Guardar",
            chooseProfilePhoto: "Escoge una foto de perfil",
                 
        }
    },
    workforceRequests:{
        title: "Solicitudes Workforce",
    },
    general:{
        loading: "Cargando...",
        warning: "Advertencia",
        noAuthorization: "No tienes autorización para ver este contenido"
    }
  };