import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  { path: "*", component: () => import("@/views/others/404.vue") },
  {
    path: "/login",
    component: () => import("@/views/auth/_LayoutLogin.vue"),
    meta: { isPublic: true },
    redirect: { name: "login" },
    children: [
      {
        path: "/login",
        name: "login",
        meta: {
          title: "login",
          descriptionLayout: "Inicio de sesión",
        },
        component: () => import("@/views/auth/Login.vue"),
      },
      {
        path: "/unauthorized",
        name: "unauthorized",
        meta: {
          title: "unauthorizedOkan",
          descriptionLayout: "Okan sin autorización",
        },
        component: () => import("@/views/others/Unauthorized.vue"),
      },
    ],
  },
  {
    path: "/loginAzure",
    component: () => import("@/views/auth/_LayoutLogin.vue"),
    meta: { isPublic: true },
    redirect: { name: "loginAzure" },
    children: [
      {
        path: "/loginAzure",
        name: "loginAzure",
        meta: {
          title: "login",
          descriptionLayout: "Inicio de sesión",
        },
        component: () => import("@/views/auth/LoginOffice.vue"),
      },
      {
        path: "/unauthorized",
        name: "unauthorized",
        meta: {
          title: "unauthorizedOkan",
          descriptionLayout: "Okan sin autorización",
        },
        component: () => import("@/views/others/Unauthorized.vue"),
      },
    ],
  },

  {
    path: "/saludobienvenida",
    component: () => import("@/views/newUser/Layout.vue"),
    meta: { isPublic: true },
    redirect: { name: "newuser" },
    children: [
      {
        path: "/saludobienvenida",
        name: "Newuser",
        meta: {
          title: "Nuevo usuario"
        },
        component: () => import("@/views/newUser/newUser.vue"),
      },
      {
        path: "pasosiniciales",
        name: "PasosIniciales",
        meta: {
          title: "Pasos Iniciales"
        },
        component: () => import("@/views/newUser/firststeps.vue"),
      },
    ],
  }
  ,
  {
    path: "/",
    component: () => import("@/views/auth/_Layout.vue"),
    redirect: { name: "home" },
    children: [
      {
        path: "ayuda",
        name: "helpHello",
        meta: {
          title: "Ayuda",
          descriptionLayout: "Ayuda",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: true,
              href: "home",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/components/Help.vue"),
      },
      {
        path: "home",
        name: "home",
        meta: {
          title: "Home",
          descriptionLayout: "Noticas y home",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: true,
              href: "home",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/home/IndexHome.vue"),
      },
      {
        path: "podcast",
        name: "podcast",
        meta: {
          title: "Podcast",
          descriptionLayout: "Podcast",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Podcast",
              disabled: true,
              href: "podcast",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/home/Podcast.vue"),
      },
      {
        path: "news",
        name: "listNews",
        meta: {
          title: "BRMNews",
          descriptionLayout: "Noticas y home",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "BRMNews",
              disabled: true,
              href: "listNews",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/home/IndexNews.vue"),
      },
      {
        path: "news/:id",
        name: "newsDetails",
        meta: {
          title: "BRMNews",
          descriptionLayout: "Noticas y home",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "BRMNews",
              disabled: false,
              href: "listNews",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "details",
              disabled: true,
              href: "newsDetails",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/home/NewsDetails.vue"),
      },
      {
        path: "app",
        name: "apps",
        meta: {
          title: "Apps",
          descriptionLayout: "Aplicaciones",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "apps",
              disabled: true,
              href: "apps",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/application/Apps.vue"),
      },
      {
        path: "app/details/:id",
        name: "appDetails",
        meta: {
          title: "Apps",
          descriptionLayout: "Aplicaciones",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "apps",
              disabled: false,
              href: "apps",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "details",
              disabled: true,
              href: "appDetails",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/application/Details.vue"),
      },
      {
        path: "app/reporting/:id",
        name: "appReporting",
        meta: {
          title: "Apps",
          descriptionLayout: "Aplicaciones",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "apps",
              disabled: false,
              href: "apps",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "details",
              disabled: true,
              href: "appDetails",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/application/Reporting.vue"),
      },
      {
        path: "profile",
        name: "profile",
        meta: {
          title: "MyProfile",
          descriptionLayout: "Perfil usuario",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "profile",
              disabled: true,
              href: "profile",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/user/Profile.vue"),
      },
      {
        path: "horas/extras",
        name: "extraHours",
        meta: {
          title: "Overtime",
          descriptionLayout: "Horas extras",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Horas extras",
              disabled: true,
              href: "extraHours",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/extraHours/Index.vue"),
      },
      {
        path: "voluntary",
        name: "voluntaryRetirement",
        meta: {
          title: "VoluntaryRetirement",
          descriptionLayout: "Retiro voluntario",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "profile",
              disabled: false,
              href: "profile",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Renuncia voluntaria",
              disabled: true,
              href: "voluntaryRetirement",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/resign/VoluntaryRetirement.vue"),
      },
      {
        path: "solicitudes",
        name: "actionToday",
        meta: {
          title: "Requests",
          descriptionLayout: "Solicitudes",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Requests",
              disabled: true,
              href: "actionToday",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/user/ActionToday.vue"),
      },
      {
        path: "solicitudes/voluntary",
        name: "reportRetirement",
        meta: {
          title: "VoluntaryResignationReport",
          descriptionLayout: "Reporte de renuncia voluntaria",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Requests",
              disabled: false,
              href: "actionToday",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reporte de solicitudes",
              disabled: true,
              href: "reportRetirement",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/resign/ReportRetirement.vue"),
      },
      {
        path: "programas/brm",
        name: "programsBrm",
        meta: {
          title: "BRMPrograms",
          descriptionLayout: "Programas BRM",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "BRMPrograms",
              disabled: true,
              href: "programsBrm",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/lifeControl/Index.vue"),
      },
      {
        path: "la/ficha",
        name: "referenceCard",
        meta: {
          title: "IHaveTheFile",
          descriptionLayout: "Le tengo la ficha",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "IHaveTheSheet",
              disabled: true,
              href: "programsBrm",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/lifeControl/ReferencesCard.vue"),
      },
      {
        path: "control/life",
        name: "lifeControl",
        meta: {
          title: "LifeControl",
          descriptionLayout: "Control de vida",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "LifeControl",
              disabled: true,
              href: "lifeControl",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/lifeControl/IndexControl.vue"),
      },
      {
        path: "control/life/:id",
        name: "projectLife",
        meta: {
          title: "LifeControl",
          descriptionLayout: "Control de vida",
          // este es exclusivo de mobile
        },
        component: () => import("@/views/lifeControl/ProjectDetail.vue"),
      },
      {
        path: "labor/relations",
        name: "laborRelations",
        meta: {
          title: "LaborRelations",
          descriptionLayout: "Relaciones laborales",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "LaborRelations",
              disabled: true,
              href: "laborRelations",
              active: "router-link-active",
            },
          ],
        },
        component: () =>
          import("@/views/laborRelationsModule/Index.vue"),
      },
      {
        path: "labor/relations/documents",
        name: "documentTracking",
        meta: {
          title: "LaborRelationsDocuments",
          descriptionLayout: "Relaciones laborales documentos",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "LaborRelations",
              disabled: true,
              href: "laborRelations",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Documents",
              disabled: true,
              href: "documentTracking",
              active: "router-link-active",
            },
          ],
        },
        component: () =>
          import("@/views/laborRelationsModule/DocumentTracking.vue"),
      },
      {
        path: "reference",
        name: "referenceReport",
        meta: {
          title: "ReferralReport",
          descriptionLayout: "Reporte de referidos",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Referrals",
              disabled: true,
              href: "referenceReport",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/reference/Index.vue"),
      },
      {
        path: "la/ficha/detalle/:id",
        name: "referenceReportDetail",
        meta: {
          title: "ReferralReport",
          descriptionLayout: "Reporte de referidos",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Referrals",
              disabled: true,
              href: "referenceReport",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "ReferralDetail",
              disabled: true,
              href: "referenceReportDetail",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/reference/ReferenceDetails.vue"),
      },
      {
        path: "linguo",
        name: "linguo",
        meta: {
          title: "Linguo",
          descriptionLayout: "Linguo",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Linguo",
              disabled: true,
              href: "linguo",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/application/Linguo.vue"),
      },
      {
        path: "linguo/dev",
        name: "linguoDev",
        meta: {
          title: "LinguoForDevelopers",
          descriptionLayout: "Linguo para desarrolladores",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "LinguoDev",
              disabled: true,
              href: "linguo dev",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/application/LinguoDev.vue"),
      },
      {
        path: "linguo/marcas/:id",
        name: "linguoBrands",
        meta: {
          title: "LinguoForBrands",
          descriptionLayout: "Linguo para marcas",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "LinguoBrands",
              disabled: true,
              href: "linguoBrands",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/application/LinguoBrands.vue"),
      },
      {
        path: "seguridad",
        name: "security",
        meta: {
          title: "SecurityModule",
          descriptionLayout: "Módulo de seguridad",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "InformationSecurity",
              disabled: true,
              href: "security",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/security/Index.vue"),
      },
      {
        path: "seguridad/ausencias",
        name: "securityAbsences",
        meta: {
          title: "SecurityModule",
          descriptionLayout: "Módulo de seguridad",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "AbsenceList",
              disabled: true,
              href: "security",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/security/AbsenceList.vue"),
      },
      {
        path: "seguridad/manager/add",
        name: "recordManager",
        meta: {
          title: "CorporateDocumentsForManagers",
          descriptionLayout: "Documentos corporativos para gerentes",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "InformationSecurity",
              disabled: true,
              href: "security",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "DeliveryReport",
              disabled: true,
              href: "recordManager",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/security/RecordsManager.vue"),
      },
      {
        path: "seguridad/report/bonus",
        name: "RecordReport",
        meta: {
          title: "ActReports",
          descriptionLayout: "Reporte de Actas",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "InformationSecurity",
              disabled: true,
              href: "security",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "MinutesReport",
              disabled: true,
              href: "RecordReport",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/security/RecordDocument.vue"),
      },
      {
        path: "seguridad/report/delete",
        name: "RecordDelete",
        meta: {
          title: "DeletedActReports",
          descriptionLayout: "Reporte de Actas eliminadas",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "InformationSecurity",
              disabled: true,
              href: "security",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "DeletedMinutesReport",
              disabled: true,
              href: "RecordDelete",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/security/RecordsDeleted.vue"),
      },
      {
        path: "forms",
        name: "forms",
        meta: {
          title: "BRMQuestion",
          descriptionLayout: "BRM Pregunta",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "BRMQuestion",
              disabled: true,
              href: "forms",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/forms/Index.vue"),
      },
      {
        path: "forms/details/:id",
        name: "formsDetails",
        meta: {
          title: "FormDetails",
          descriptionLayout: "Formularios detalles",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "BRMQuestion",
              disabled: false,
              href: "forms",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Form",
              disabled: true,
              href: "formsDetails",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/forms/Details.vue"),
      },
      {
        path: "forms/details/answers/:id",
        name: "formsAnswers",
        meta: {
          title: "FormResponses",
          descriptionLayout: "Respuestas formularios",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "BRMQuestion",
              disabled: false,
              href: "forms",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Answer",
              disabled: true,
              href: "formsAnswers",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/forms/FormsAnswers.vue"),
      },
      {
        path: "forms/safe/passage/:result",
        name: "formsSagePassage",
        meta: {
          title: "SafeConduct",
          descriptionLayout: "Salvo conducto",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "BRMQuestion",
              disabled: false,
              href: "forms",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "details",
              disabled: true,
              href: "formsSagePassage",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/forms/SafePassage.vue"),
      },
      {
        path: "ReglamentoyPoliticas",
        name: "tyc",
        meta: {
          title: "RegulationsAndPolicies",
          descriptionLayout: "Reglamentos y Políticas",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "RegulationsAndPolicies",
              disabled: true,
              href: "tyc",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/terms/Index.vue"),
      },
      {
        path: "ReglamentoyPoliticas/detalle",
        name: "tycDetail",
        meta: {
          title: "TermsAndConditions",
          descriptionLayout: "Términos y condiciones",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "RegulationsAndPolicies",
              disabled: true,
              href: "tyc",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Detail",
              disabled: true,
              href: "tycDetail",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/terms/Details.vue"),
      },
      {
        path: "document",
        name: "documents",
        meta: {
          title: "CorporateDocuments",
          descriptionLayout: "Documentos corporativos",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "CorporateDocuments",
              disabled: true,
              href: "documents",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/document/Index.vue"),
      },
      {
        path: "document/details/:id/:docUser",
        name: "documentDetails",
        meta: {
          title: "CorporateDocumentsDetails",
          descriptionLayout: "Documentos corporativos detalles",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Documents",
              disabled: false,
              href: "documents",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Signature",
              disabled: true,
              href: "documentDetails",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/document/Details.vue"),
      },
      {
        path: "document/manager/add",
        name: "documentManager",
        meta: {
          title: "CorporateDocumentsForManagers",
          descriptionLayout: "Documentos corporativos para gerentes",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "PayrollReport",
              disabled: false,
              href: "marginCommisions",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "NonSalariedBonus",
              disabled: true,
              href: "documentManager",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/document/DocumentManager.vue"),
      },
      {
        path: "document/report/bonus",
        name: "documentReportBonus",
        meta: {
          title: "NonSalaryBonusDocumentReport",
          descriptionLayout: "Reporte de documentos de bono no salarial",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "PayrollReport",
              disabled: false,
              href: "marginCommisions",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "NonSalariedBonusReport",
              disabled: true,
              href: "documentReportBonus",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/document/ReportDocument.vue"),
      },
      {
        path: "document/report/delete",
        name: "documentDeleteReportBonus",
        meta: {
          title: "DeletedNonSalaryBonusDocumentsReport",
          descriptionLayout: "Reporte de documentos de bono eliminados",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "PayrollReport",
              disabled: false,
              href: "marginCommisions",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "DeletedBonusReport",
              disabled: true,
              href: "documentDeleteReportBonus",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/document/ReportDeleteDocument.vue"),
      },
      {
        path: "document/legal/add",
        name: "documentLegal",
        meta: {
          title: "CorporateDocuments",
          descriptionLayout: "Documentos corporativos",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "PayrollReport",
              disabled: false,
              href: "marginCommisions",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Documents",
              disabled: true,
              href: "documentLegal",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/document/DocumentLegal.vue"),
      },
      {
        path: "document/legal/report",
        name: "documentReportLegal",
        meta: {
          title: "DocumentsReport",
          descriptionLayout: "Reporte de documentos",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "PayrollReport",
              disabled: false,
              href: "marginCommisions",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "DocumentsReport",
              disabled: true,
              href: "documentReportLegal",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/document/ReportDocumentLegal.vue"),
      },
      {
        path: "document/legal/delete",
        name: "documentDeleteReportLegal",
        meta: {
          title: "DeletedDocumentsReport",
          descriptionLayout: "Reporte de documentos eliminados",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "PayrollReport",
              disabled: false,
              href: "marginCommisions",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "DeletedDocumentsReport",
              disabled: true,
              href: "documentDeleteReportLegal",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/document/ReportDeleteDocumentLegal.vue"),
      },
      {
        path: "document/ingresos",
        name: "documentLogin",
        meta: {
          title: "OKANIncomeReport",
          descriptionLayout: "Reporte de Ingresos",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "PayrollReport",
              disabled: true,
              href: "marginCommisions",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "LoginReport",
              disabled: true,
              href: "documentLogin",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/document/ReportLogin.vue"),
      },
      {
        path: "consolidate",
        name: "consolidate",
        meta: {
          title: "ConsolidatedReport",
          descriptionLayout: "Informe Consolidado",
          // en espera breadcrums
        },
        component: () => import("@/views/consolidate/IndexConsolidate.vue"),
      },
      {
        path: "ocupation",
        name: "ocupation",
        meta: {
          title: "Occupation",
          descriptionLayout: "Ocupación detalles",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Ocupación",
              disabled: true,
              href: "ocupation",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/ocupation/Index.vue"),
      },
      {
        path: "ocupation/create/:id",
        name: "ocupationCreate",
        meta: {
          title: "Occupation",
          descriptionLayout: "Ocupación detalles",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Ocupación",
              disabled: false,
              href: "ocupation",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Editar ocupación",
              disabled: true,
              href: "ocupationCreate",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/ocupation/AddSite.vue"),
      },
      {
        path: "malla",
        name: "malla",
        meta: {
          title: "ShiftSchedule",
          descriptionLayout: "Malla de turno",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "ShiftGrid",
              disabled: true,
              href: "malla",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/malla/Index.vue"),
      },
      {
        path: "service",
        name: "service",
        meta: {
          title: "Servicios",
          descriptionLayout: "Servicios",
          // breadcrums mobile
        },
        component: () => import("@/components/Service.vue"),
      },
      {
        path: "comisiones",
        name: "commissions",
        meta: {
          title: "Commissions",
          descriptionLayout: "Comisiones",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Claims",
              disabled: true,
              href: "commissions",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/commissions/Index.vue"),
      },
      {
        path: "margen/comisiones",
        name: "marginCommisions",
        meta: {
          title: "CommissionMargins",
          descriptionLayout: "Margen de comisiones",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "PayrollReport",
              disabled: true,
              href: "marginCommisions",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/marginCommisions/Index.vue"),
      },
      {
        path: "margen/comisiones/upload",
        name: "uploadMargin",
        meta: {
          title: "AttachCommissionMargins",
          descriptionLayout: "Adjuntar margenes de comisiones",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "PayrollReport",
              disabled: false,
              href: "marginCommisions",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "AttachFile",
              disabled: true,
              href: "uploadMargin",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/marginCommisions/UploadFile.vue"),
      },
      {
        path: "margen/comisiones/report/sign",
        name: "reportSign",
        meta: {
          title: "SignaturesReport",
          descriptionLayout: "Reporte de firmas",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "PayrollReport",
              disabled: false,
              href: "marginCommisions",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "ReportByUsers",
              disabled: true,
              href: "reportSign",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/marginCommisions/ReportSign.vue"),
      },
      {
        path: "margen/comisiones/report/percentage",
        name: "reportPercentage",
        meta: {
          title: "SignaturesReportByPercentage",
          descriptionLayout: "Reporte de firmas por porcentaje",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "PayrollReport",
              disabled: false,
              href: "marginCommisions",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "ReportByUsers",
              disabled: false,
              href: "reportSign",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "ReportByPercentages",
              disabled: true,
              href: "reportPercentage",
              active: "router-link-active",
            },
          ],
        },
        component: () =>
          import("@/views/marginCommisions/ReportPercentage.vue"),
      },
      {
        path: "margen/comisiones/sign",
        name: "commissionsSign",
        meta: {
          title: "CommissionMinutes",
          descriptionLayout: "Acta de comisiones",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "PayrollReport",
              disabled: false,
              href: "marginCommisions",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Signature",
              disabled: true,
              href: "commissionsSign",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/marginCommisions/CommissionsSign.vue"),
      },
      {
        path: "margen/comisiones/provision",
        name: "provisionCommissions",
        meta: {
          title: "CommissionProvision",
          descriptionLayout: "Provisión de comisiones",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "PayrollReport",
              disabled: false,
              href: "marginCommisions",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Provisions",
              disabled: true,
              href: "provisionCommissions",
              active: "router-link-active",
            },
          ],
        },
        component: () =>
          import("@/views/marginCommisions/ProvisionCommission.vue"),
      },
      {
        path: "margen/comisiones/nueva/provision",
        name: "updateProvisionCommissions",
        meta: {
          title: "UpdateCommissionProvision",
          descriptionLayout: "Actualziar provisión de comisiones",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "PayrollReport",
              disabled: false,
              href: "marginCommisions",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "AttachProvisions",
              disabled: true,
              href: "updateProvisionCommissions",
              active: "router-link-active",
            },
          ],
        },
        component: () =>
          import("@/views/marginCommisions/UpdateProvisionCommission.vue"),
      },
      {
        path: "notifications",
        name: "notifications",
        meta: {
          title: "Notifications",
          descriptionLayout: "notifications",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Notifications",
              disabled: true,
              href: "Notificaciones",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/notifications/Notifications.vue"),
      },
      {
        path: "atraccion/seleccion",
        name: "selection",
        meta: {
          title: "RecruitmentAndSelection",
          descriptionLayout: "Atracción y selección",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "AttractionAndSelection",
              disabled: true,
              href: "selection",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/selection/Index.vue"),
      },
      {
        path: "atraccion/seleccion/vacantes",
        name: "selectionVacancies",
        meta: {
          title: "CreateVacancies",
          descriptionLayout: "Crear vacantes",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "AttractionAndSelection",
              disabled: false,
              href: "selection",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "CreateVacancies",
              disabled: true,
              href: "selectionVacancies",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/selection/Vacancies.vue"),
      },
      {
        path: "atraccion/seleccion/candidatos",
        name: "candidateList",
        meta: {
          title: "ListCandidates",
          descriptionLayout: "Listar candidatos",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "AttractionAndSelection",
              disabled: false,
              href: "selection",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "ListCandidates",
              disabled: true,
              href: "candidateList",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/selection/CandidateList.vue"),
      },
      {
        path: "atraccion/seleccion/candidatos/:id",
        name: "candidateDetails",
        meta: {
          title: "CandidateDetails",
          descriptionLayout: "Detalle de candidatos candidatos",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "AttractionAndSelection",
              disabled: false,
              href: "selection",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "ListCandidates",
              disabled: false,
              href: "candidateList",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "CandidateDetails",
              disabled: false,
              href: "candidateDetails",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/selection/CandidateDetails.vue"),
      },
      {
        path: "atraccion/seleccion/todasvacantes",
        name: "vacanciesList",
        meta: {
          title: "ListVacancies",
          descriptionLayout: "Listar vacantes",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "AttractionAndSelection",
              disabled: false,
              href: "selection",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "ListVacancies",
              disabled: true,
              href: "vacanciesList",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/selection/AllVacancies.vue"),
      },
      {
        path: "atraccion/seleccion/todasvacantes/candidatos/:id",
        name: "allCandidatesList",
        meta: {
          title: "ListCandidates",
          descriptionLayout: "Listar candidatos",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "AttractionAndSelection",
              disabled: false,
              href: "selection",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "ListVacancies",
              disabled: true,
              href: "vacanciesList",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "ListCandidates",
              disabled: true,
              href: "allCandidatesList",
              active: "router-link-active",
            }
          ],
        },
        component: () => import("@/views/selection/AllCandidates.vue"),
      },
      {
        path: "nuevoDesarrollo",
        name: "newDevelopment",
        meta: {
          title: "NewDevelopment",
          descriptionLayout: "Nuevo Desarrollo",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "NewDevelopment",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/Index.vue"),
      },
      {
        path: "nuevoDesarrollo/own",
        name: "ownDevelopment",
        meta: {
          title: "OwnRequests",
          descriptionLayout: "Solicitudes propias",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "NewDevelopment",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "OwnRequests",
              disabled: true,
              href: "ownDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/IndexOwnRequests.vue"),
      },
      { 
        path: "nuevoDesarrollo/own/create",
        name: "createDevelopment",
        meta: {
          title: "CreateNewRequest",
          descriptionLayout: "Crear nueva solicitud",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "NewDevelopment",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "OwnRequests",
              disabled: true,
              href: "ownDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "CreateNewRequest",
              disabled: true,
              href: "createDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/CreateProcesses.vue"),
      },
      {
        path: "nuevoDesarrollo/own/created",
        name: "createdDevelopment",
        meta: {
          title: "CreatedRequests",
          descriptionLayout: "Solicitudes creadas",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "NewDevelopment",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "OwnRequests",
              disabled: true,
              href: "ownDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "CreatedRequests",
              disabled: true,
              href: "createdDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/ListCreatedDevelopment.vue"),
      },
      {
        path: "nuevoDesarrollo/own/progress",
        name: "progressDevelopment",
        meta: {
          title: "RequestsInProgress",
          descriptionLayout: "Solicitudes en curso",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "NewDevelopment",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "OwnRequests",
              disabled: true,
              href: "ownDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "OngoingRequests",
              disabled: true,
              href: "progressDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/ListProgressDevelopment.vue"),
      },
      {
        path: "nuevoDesarrollo/own/draft",
        name: "draftDevelopment",
        meta: {
          title: "DraftRequest",
          descriptionLayout: "Solicitud en borrador",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "NewDevelopment",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "OwnRequests",
              disabled: true,
              href: "ownDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "DraftRequest",
              disabled: true,
              href: "draftDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/ListDraftDevelopment.vue"),
      },
      {
        path: "nuevoDesarrollo/own/draftEdit",
        name: "draftEditDevelopment",
        meta: {
          title: "EditDraftRequest",
          descriptionLayout: "SEditar solicitud en Borrador",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "NewDevelopment",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "OwnRequests",
              disabled: true,
              href: "ownDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "EditDraftRequest",
              disabled: true,
              href: "draftEditDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/DraftProcesses.vue"),
      },
      {
        path: "nuevoDesarrollo/copy",
        name: "copyDevelopment",
        meta: {
          title: "CopiedRequests",
          descriptionLayout: "Solicitudes en copia",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "NewDevelopment",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "CopyRequests",
              disabled: true,
              href: "copyDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/ListCopyDevelopment.vue"),
      },
      {
        path: "nuevoDesarrollo/copy/detalle:id",
        name: "copyDetailDevelopment",
        meta: {
          title: "RequestDetails",
          descriptionLayout: "Detalle de solicitud",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "NewDevelopment",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "CopyRequests",
              disabled: true,
              href: "copyDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "RequestDetails",
              disabled: true,
              href: "copyDetailDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/DetailCopyProcesses.vue"),
      },
      {
        path: "nuevoDesarrollo/own/detalle:id",
        name: "ownDetailDevelopment",
        meta: {
          title: "RequestDetails",
          descriptionLayout: "Detalle de solicitud",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "NewDevelopment",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "OwnRequests",
              disabled: true,
              href: "ownDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "RequestDetails",
              disabled: true,
              href: "ownDetailDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/DetailOwnProcesses.vue"),
      },
      {
        path: "nuevoDesarrollo/managment",
        name: "managementDevelopment",
        meta: {
          title: "Requests",
          descriptionLayout: "Solicitudes",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "NewDevelopment",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "PMRequests",
              disabled: true,
              href: "managementDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/IndexManagmentRequests.vue"),
      },
      {
        path: "nuevoDesarrollo/managment/asignado",
        name: "managerDevelopmentAssigned",
        meta: {
          title: "AssignedRequests",
          descriptionLayout: "Solicitudes Asignadas",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "NewDevelopment",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "PMRequests",
              disabled: true,
              href: "managementDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "AssignedRequests",
              disabled: true,
              href: "managerDevelopmentAssigned",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/ListManagmentDevelopment.vue"),
      },
      {
        path: "nuevoDesarrollo/managment/curso",
        name: "managerProgressDevelopment",
        meta: {
          title: "RequestsInProgress",
          descriptionLayout: "Solicitudes en curso",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "NewDevelopment",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "PMRequests",
              disabled: true,
              href: "managementDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "OngoingRequests",
              disabled: true,
              href: "managerProgressDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/ListManagmentDevelopmentProgress.vue"),
      },
      {
        path: "nuevoDesarrollo/managment/asignado/detalle:id",
        name: "managmentProgressDetailDevelopment",
        meta: {
          title: "RequestDetails",
          descriptionLayout: "Detalle de solicitud",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "NewDevelopment",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "PMRequests",
              disabled: true,
              href: "managementDevelopment",
              active: "router-link-active",
            },
            ,
            {
              icon: false,
              text: "AssignedRequests",
              disabled: true,
              href: "managerProgressDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "RequestDetails",
              disabled: true,
              href: "managmentProgressDetailDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/DetailManagmentInProcesses.vue"),
      },
      {
        path: "nuevoDesarrollo/managment/detalle:id",
        name: "managmentDetailDevelopment",
        meta: {
          title: "RequestDetails",
          descriptionLayout: "Detalle de solicitud",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "NewDevelopment",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "AssignedRequests",
              disabled: true,
              href: "managerDevelopmentAssigned",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "RequestDetails",
              disabled: true,
              href: "managmentDetailDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/DetailManagmentProcesses.vue"),
      },
      {
        path: "nuevoDesarrollo/progress:id",
        name: "detailDevelopment",
        meta: {
          title: "RequestDetails",
          descriptionLayout: "Detalle solicitud",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "NewDevelopment",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "RequestDetails",
              disabled: true,
              href: "progressDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/DetailGeneralProcesses.vue"),
      },
      {
        path: "disciplinarios",
        name: "processes",
        meta: {
          title: "DisciplinaryProcesses",
          descriptionLayout: "Procesos disciplinarios",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "DisciplinaryProcesses",
              disabled: true,
              href: "processes",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/disciplinaryProcesses/Index.vue"),
      },
      {
        path: "disciplinarios/crear",
        name: "createDisciplinaryProcesses",
        meta: {
          title: "CreateNewDisciplinaryProcess",
          descriptionLayout: "crear un nuevo proceso disciplinarios",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "DisciplinaryProcesses",
              disabled: true,
              href: "processes",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "CreateDisciplinaryProcess",
              disabled: true,
              href: "createDisciplinaryProcesses",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/disciplinaryProcesses/CreateDisciplinaryProcesses.vue"),
      },
      {
        path: "disciplinarios/list",
        name: "listDisciplinaryProcesses",
        meta: {
          title: "DisciplinaryProcessList",
          descriptionLayout: "Lista de proceso disciplinarios",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "DisciplinaryProcesses",
              disabled: true,
              href: "processes",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "CreateDisciplinaryProcess",
              disabled: true,
              href: "listDisciplinaryProcesses",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/disciplinaryProcesses/ListDisciplinaryProcesses.vue"),
      },
      {
        path: "disciplinarios/list/detalle/:id",
        name: "disciplinaryDetails",
        meta: {
          title: "DisciplinaryProcessDetails",
          descriptionLayout: "Detalle del proceso disciplinario",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "DisciplinaryProcesses",
              disabled: true,
              href: "processes",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "CreateDisciplinaryProcess",
              disabled: true,
              href: "listDisciplinaryProcesses",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "DisciplinaryProcessDetails",
              disabled: true,
              href: "disciplinaryDetails",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/disciplinaryProcesses/DisciplinaryDetails.vue"),
      },
      {
        path: "carnet",
        name: "carnet",
        meta: {
          title: "IDCard",
          descriptionLayout: "Carnet",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "carnet",
              disabled: true,
              href: "carnet",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/carnet/Index.vue"),
      },
      {
        path: "carnet/Security",
        name: "carnetSecurity",
        meta: {
          title: "SecurityIDCard",
          descriptionLayout: "Carnet seguridad",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "carnet",
              disabled: true,
              href: "carnet",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "carneSecurity",
              disabled: true,
              href: "carnetSecurity",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/carnet/CarnetSecurity.vue"),
      },
      {
        path: "reconocimientosParaTi",
        name: "ReconocimineosParaTi",
        meta: {
          title: "RecognitionForYou",
          descriptionLayout: "Reconocimientos para ti",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "RecognitionsForYou",
              disabled: true,
              href: "ReconocimineosParaTi",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/reconocimientos/Index.vue"),
      },
      {
        path: "superStars",
        name: "superStars",
        meta: {
          title: "SuperStars",
          descriptionLayout: "Super Stars",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "SuperStars",
              disabled: true,
              href: "superStars",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/reconocimientos/internals/superStars.vue"),
      },
      {
        path: "rappiRanking",
        name: "rappiRanking",
        meta: {
          title: "DeliveryStars",
          descriptionLayout: "Delivery Stars",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "DeliveryStars",
              disabled: true,
              href: "rappiRanking",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/reconocimientos/internals/deliveryStars.vue"),
      },
      {
        path: "aceOfSpades",
        name: "aceOfSpades",
        meta: {
          title: "AceOfSpades",
          descriptionLayout: "Ace Of Spades",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "AceOfSpades",
              disabled: true,
              href: "aceOfSpades",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/reconocimientos/internals/aceOfSpades.vue"),
      },
      {
        path: "allWinners",
        name: "allWinners",
        meta: {
          title: "AllWinners",
          descriptionLayout: "Todos los ganadores",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "AllWinners",
              disabled: true,
              href: "allWinners",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/reconocimientos/internals/allWinners.vue"),
      },
      {
        path: "/reconocimientos/updating-winners",
        name: "updatingWinners",
        meta: {
          title: "UpdatingWinners",
          descriptionLayout: "Actualizar Ganadores",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "UpdatingWinners",
              disabled: true,
              href: "updatingWinners",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/reconocimientos/updatingWinners.vue"),
        props: true // Esto permite pasar los parámetros de ruta como props
      },
      {
        path: "/reconocimientos/deleting-winners",
        name: "deletingWinners",
        meta: {
          title: "DeletingWinners",
          descriptionLayout: "Eliminar Ganadores",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "DeletingWinners",
              disabled: true,
              href: "deletingWinners",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/reconocimientos/deletingWinners.vue"),
      },
      {
        path: "convenios",
        name: "convenios",
        meta: {
          title: "Agreements",
          descriptionLayout: "Convenios",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Agreements",
              disabled: true,
              href: "convenios",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/convenios/Index.vue"),
      },
      {
        path: "/convenio/:id",
        name: "convenioDetalles",
        meta: {
          title: "AgreementDetails",
          descriptionLayout: "Detalles del Convenio",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Agreements",
              disabled: false,
              href: "convenios",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "details",
              disabled: true,
              href: "convenioDetalles",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/convenios/convenioDetalles.vue"),
      },
      {
        path: "workforceRequests",
        name: "workforceRequests",
        meta: {
          title: "WorkforceRequests",
          descriptionLayout: "Solicitudes Workforce",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
            },
            {
              icon: false,
              text: "WorkforceRequests",
              disabled: true,
              href: "workforceRequests",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/workforceRequests/Index.vue"),
      },
      {
        path: "workforceRequests/own/",
        name: "ownWorkforceRequests",
        meta: {
          title: "OwnWorkforceRequests",
          descriptionLayout: "Solicitudes propias",
          breadcrums: [
            {
              icon: false,
              text: "WorkforceRequests",
              disabled: false,
              href: "workforceRequests",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "OwnWorkforceRequests",
              disabled: true,
              href: "ownWorkforceRequests",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/workforceRequests/internals/ownWorkforceRequest.vue"),
      },
      {
        path: "workforceRequests/own/create/",
        name: "createWorkforceRequest",
        meta: {
          title: "CreateWorkforceRequest",
          descriptionLayout: "Crear solicitud Workforce",
          breadcrums: [
            {
              icon: false,
              text: "WorkforceRequests",
            },
            {
              icon: false,
              text: "OwnWorkforceRequests",
              disabled: false,
              href: "ownWorkforceRequests",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "CreateWorkforceRequest",
              disabled: true,
              href: "createWorkforceRequest",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/workforceRequests/internals/createWorkforceRequest.vue"),
      },
      {
        path: "workforceRequests/own/created",
        name: "createdWorkforceRequest",
        meta: {
          title: "CreatedWorkforceRequests",
          descriptionLayout: "Solicitudes creadas",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "WorkforceRequests",
              disabled: true,
              href: "workforceRequests",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "OwnWorkforceRequests",
              disabled: true,
              href: "ownWorkforceRequests",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "CreatedWorkforceRequests",
              disabled: true,
              href: "createdWorkforceRequest",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/workforceRequests/internals/listCreatedWorkforceRequest.vue"),
      },
      {
        path: "workforceRequests/own/progress",
        name: "progressWorkforceRequest",
        meta: {
          title: "WorkforceRequestsInProgress",
          descriptionLayout: "Solicitudes en curso",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "WorkforceRequests",
              disabled: true,
              href: "workforceRequests",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "OwnWorkforceRequests",
              disabled: true,
              href: "ownWorkforceRequests",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "OngoingWorkforceRequests",
              disabled: true,
              href: "progressWorkforceRequest",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/workforceRequests/internals/listProgressWorkforceRequest.vue"),
      },
      {
        path: "workforceRequests/own/draft",
        name: "draftWorkforceRequest",
        meta: {
          title: "DraftWorkforceRequest",
          descriptionLayout: "Solicitud en borrador",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "WorkforceRequests",
              disabled: true,
              href: "workforceRequests",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "OwnWorkforceRequests",
              disabled: true,
              href: "ownWorkforceRequests",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "DraftRequest",
              disabled: true,
              href: "draftWorkforceRequest",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/workforceRequests/internals/listDraftWorkforceRequest.vue"),
      },
      {
        path: "workforceRequests/copy",
        name: "copyWorkforceRequest",
        meta: {
          title: "CopiedWorkforceRequests",
          descriptionLayout: "Solicitudes en copia",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "WorkforceRequests",
              disabled: true,
              href: "workforceRequests",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "CopyRequests",
              disabled: true,
              href: "copyWorkforceRequest",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/workforceRequests/internals/listCopyWorkforceRequest.vue"),
      },
      {
        path: "workforceRequests/managment",
        name: "managementWorkforceRequest",
        meta: {
          title: "Requests",
          descriptionLayout: "Solicitudes",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "WorkforceRequests",
              disabled: true,
              href: "workforceRequests",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "PMRequests",
              disabled: true,
              href: "managementWorkforceRequest",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/workforceRequests/internals/indexManagmentWorkforceRequest.vue"),
      },
      {
        path: "workforceRequests/managment/assigned",
        name: "managerWorkforceRequestAssigned",
        meta: {
          title: "AssignedRequests",
          descriptionLayout: "Solicitudes Asignadas",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "WorkforceRequests",
              disabled: true,
              href: "workforceRequests",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "PMRequests",
              disabled: true,
              href: "managementWorkforceRequest",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "AssignedRequests",
              disabled: true,
              href: "managerWorkforceRequestAssigned",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/workforceRequests/internals/listManagmentWorkforceRequest.vue"),
      },
      {
        path: "workforceRequests/managment/progress",
        name: "managerProgressWorkforceRequest",
        meta: {
          title: "RequestsInProgress",
          descriptionLayout: "Solicitudes en curso",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "WorkforceRequests",
              disabled: true,
              href: "workforceRequests",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "PMRequests",
              disabled: true,
              href: "managementWorkforceRequest",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "OngoingRequests",
              disabled: true,
              href: "managerProgressWorkforceRequest",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/workforceRequests/internals/listManagmentWorkforceRequestProgress.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
