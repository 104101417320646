import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Vuetify from "vuetify";
import Notifications from "vue-notification";
import { isLoggedIn, validateSession } from "./utils/auth";
import vueVimeoPlayer from "vue-vimeo-player";
import VueTour from "vue-tour";
import VueHtml2Canvas from "vue-html2canvas";
import { translations } from "./locate"; // Importa las traducciones

require("vue-tour/dist/vue-tour.css");

import VueGapi from "vue-gapi";

Vue.use(Notifications);
Vue.use(vueVimeoPlayer);
Vue.use(VueTour);
Vue.use(VueHtml2Canvas);
Vue.use(Vuetify);

Vue.use(VueGapi, {
  clientId:
    "705545961295-sli4gice1d24p6opdetb065f4d0na7ps.apps.googleusercontent.com",
  scope: "profile email",
});

import VueVideoPlayer from "vue-video-player";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
import "videojs-flash";
import "videojs-contrib-hls/dist/videojs-contrib-hls";
Vue.use(VueVideoPlayer);

Vue.config.productionTip = false;

// Función de traducción global
Vue.prototype.$translate = function (key, defaultValue = "") {
  const currentLocale = this.$root.$data.currentLanguage || "es"; // Idioma actual
  const keys = key.split("."); // Divide la clave en partes (por ejemplo, 'home.buttonSeeMore')
  let result = translations[currentLocale]; // Comienza con las traducciones del idioma actual

  // Recorre las claves anidadas
  for (let i = 0; i < keys.length; i++) {
    if (result[keys[i]] === undefined) {
      return defaultValue || key; // Si no se encuentra la clave, devuelve el valor por defecto
    }
    result = result[keys[i]]; // Navega en el objeto
  }

  return result || defaultValue || key; // Si no se encuentra la traducción, devuelve el valor por defecto
};

import es from "vuetify/es5/locale/es";

router.beforeEach(async (to, from, next) => {
  if (!to.matched.some((record) => record.meta.isPublic) && !isLoggedIn()) {
    next({
      path: "/unauthorized",
    });
  } else {
    const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find((record) => record.meta && record.meta.title);

    await validateSession();

    if (nearestWithTitle) {
      const titleKey = to.meta.title;
      router.app.$emit('update-title', titleKey);
    }

    if (
      to.meta.role != store.getters.getInfoUser &&
      !to.name == "unauthorized"
    ) {
      next({
        path: "/unauthorized",
      });
    }
    next();
  }
});

new Vue({
  router,
  store,
  data: {
    // Recuperar el idioma desde localStorage o usar 'es' por defecto
    currentLanguage: localStorage.getItem('language') || "es", // Idioma por defecto
  },
  vuetify: new Vuetify({
    lang: {
      locales: { es }, // Asegúrate de que las traducciones estén disponibles
      current: localStorage.getItem('language') || "es", // Asignar el idioma recuperado o 'es' por defecto
    },
  }),
  methods: {
    // Método para cambiar el idioma dinámicamente
    setLanguage(lang) {
      this.currentLanguage = lang;
      localStorage.setItem('language', lang); // Guardar el idioma seleccionado en localStorage
      this.$vuetify.lang.current = lang; // Cambiar idioma de Vuetify
      const titleKey = this.$route.meta.title || ""; // Obtén la clave actual del título
      document.title = this.$translate(titleKey); // Actualiza el título al idioma seleccionado

      window.location.reload();
    },
    detectBrowserLanguage() {
      const browserLanguage = navigator.language || navigator.userLanguage;
      // Convertir el idioma en un formato estándar (es, en, etc.)
      return browserLanguage.split('-')[0] || 'es'; // Por defecto 'es' si no se detecta
    },
  },
  created() {
    // Si hay un idioma guardado en localStorage, se actualizará automáticamente
    const savedLanguage = localStorage.getItem('language');
    const browserLanguage = this.detectBrowserLanguage();
    this.currentLanguage = savedLanguage  || browserLanguage;
    this.$vuetify.lang.current = savedLanguage || browserLanguage; // Establecer idioma en Vuetify

    if (!savedLanguage) {
      // Si existe, establecerlo como el idioma actual
      localStorage.setItem('language', browserLanguage); // Guardar idioma detectado
    } 

    // Escuchar el evento 'update-title' para actualizar el título
    this.$on("update-title", (titleKey) => {
      const translatedTitle = this.$translate(`titleApp.${titleKey}`); // Traducir el título
      console.log(`Título traducido: ${translatedTitle}`); // Muestra la traducción en consola
      document.title = translatedTitle; // Actualiza el título de la página
    });
  },
  render: (h) => h(App),
}).$mount("#app");
